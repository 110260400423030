class PresupuestoService {
  constructor() {}
  resources = () => ({
    opexf: {
      uri: `/v1/presupuesto/archivo1`,
      method: ['post'],
      headers: { 'Content-Type': 'multipart/form-data' }
    },
    opexa: {
      uri: `/v1/presupuesto/archivo2`,
      method: ['post'],
      headers: { 'Content-Type': 'multipart/form-data' }
    },
    capexa: {
      uri: `/v1/presupuesto/archivo3`,
      method: ['post'],
      headers: { 'Content-Type': 'multipart/form-data' }
    },
    url: {
      uri: `/v1/presupuesto/url`,
      method: ['post'],
      headers: { 'Content-Type': 'application/json' }
    },
    archivos: {
      uri: `/v1/presupuesto/archivos`,
      method: ['get']
    },
    listJefes: {
      uri: `/v1/usuario/usuarios-modulos`,
      method: ['get']
    },
    sendemail: {
      uri: `/v1/notificate/comites`,
      method: ['post'],
      headers: { 'Content-Type': 'application/json' }
    }
  });
}

export default PresupuestoService;
