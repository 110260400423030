import moment from 'moment';
import fire from '@/fire';

async function notificateActas(idResponsable, codeActa) {
  const notifications = {
    title: 'Nueva Acta de Reunión',
    idresponsable: idResponsable,
    visited: false,
    iconbg: '#ffffff',
    //icon: 'mdi-file-document',
    ticon: false,
    icon: require('@/assets/images/icon/actas.svg'),
    desc: `Se ha generado el acta <b>N° ${codeActa}</b>, valide sus acuerdos.`,
    to: '/actas/matriz-aac',
    time: moment().format('YYYY-MM-DD HH:mm:ss')
  };
  await fire
    .database()
    .ref('notifications')
    .push(notifications);
  //console.log('RES ->', res);
}

async function notificateComite(idResponsable, nombreComite) {
  const notifications = {
    title: `Nuevo Comité - ${nombreComite}`,
    idresponsable: idResponsable,
    visited: false,
    iconbg: '#ffffff',
    //icon: 'mdi-file-document',
    ticon: false,
    icon: require('@/assets/images/icon/calendario.svg'),
    desc: `Usted ha sido invitado al comité <b>${nombreComite}</b>`,
    to: '/calendario-comites/comite',
    time: moment().format('YYYY-MM-DD HH:mm:ss')
  };
  await fire
    .database()
    .ref('notifications')
    .push(notifications);
  //console.log('RES ->', res);
}

async function notificateEvento(idResponsable, nombreEvento) {
  const notifications = {
    title: `Nuevo Evento ${nombreEvento}`,
    idresponsable: idResponsable,
    visited: false,
    iconbg: '#ffffff',
    //icon: 'mdi-file-document',
    ticon: false,
    icon: require('@/assets/images/icon/calendario.svg'),
    desc: `Usted ha sido invitado al evento <b>${nombreEvento}</b>`,
    to: '/calendario-comites/evento',
    time: moment().format('YYYY-MM-DD HH:mm:ss')
  };
  await fire
    .database()
    .ref('notifications')
    .push(notifications);
  //console.log('RES ->', res);
}

async function notificateIndicadores(idResponsable, nombreIndicador) {
  const notifications = {
    title: `Nuevo Indicador de Gestión ${nombreIndicador}`,
    idresponsable: idResponsable,
    visited: false,
    iconbg: '#ffffff',
    //icon: 'mdi-file-document',
    ticon: false,
    icon: require('@/assets/images/icon/indicadores-gestion.svg'),
    desc: `Se ha generado un link de Power Bi para visualizar el Dashboard`,
    to: '/indicadores/matriz',
    time: moment().format('YYYY-MM-DD HH:mm:ss')
  };
  await fire
    .database()
    .ref('notifications')
    .push(notifications);
  //console.log('RES ->', res);
}

async function notificatePresupuesto(idResponsable, mensaje, asunto) {
  const notifications = {
    title: `Presupuesto ${asunto}`,
    idresponsable: idResponsable,
    visited: false,
    iconbg: '#ffffff',
    //icon: 'mdi-file-document',
    ticon: false,
    icon: require('@/assets/images/icon/presupuesto.svg'),
    desc: `${mensaje}`,
    to: '/presupuesto/dashboard',
    time: moment().format('YYYY-MM-DD HH:mm:ss')
  };
  await fire
    .database()
    .ref('notifications')
    .push(notifications);
  //console.log('RES ->', res);
}

async function notificateAvancePresupuesto(idResponsable) {
  const notifications = {
    title: `Avance Presupuestal OPEX/CAPEX`,
    idresponsable: idResponsable,
    visited: false,
    iconbg: '#ffffff',
    //icon: 'mdi-file-document',
    ticon: false,
    icon: require('@/assets/images/icon/presupuesto.svg'),
    desc: `Se encuentra disponible el Dashboard de avance presupuestal`,
    to: '/presupuesto/dashboard',
    time: moment().format('YYYY-MM-DD HH:mm:ss')
  };
  await fire
    .database()
    .ref('notifications')
    .push(notifications);
  //console.log('RES ->', res);
}

async function notificateProyectos(idResponsable, nombreProyecto) {
  const notifications = {
    title: `Nueva Tarea Asignada`,
    idresponsable: idResponsable,
    visited: false,
    iconbg: '#ffffff',
    //icon: 'mdi-file-document',
    ticon: false,
    icon: require('@/assets/images/icon/proyectos.svg'),
    desc: `Formas parte del proyecto  <b>${nombreProyecto}</b>, valida tus tareas`,
    to: '/proyectos/matriz',
    time: moment().format('YYYY-MM-DD HH:mm:ss')
  };
  await fire
    .database()
    .ref('notifications')
    .push(notifications);
  //console.log('RES ->', res);
}

async function notificateProyectosCalendar(idResponsable, mensaje) {
  const notifications = {
    title: `Recordatorio`,
    idresponsable: idResponsable,
    visited: false,
    iconbg: '#ffffff',
    //icon: 'mdi-file-document',
    ticon: false,
    icon: require('@/assets/images/icon/calendario.svg'),
    desc: mensaje,
    to: '/proyectos/matriz',
    time: moment().format('YYYY-MM-DD HH:mm:ss')
  };
  await fire
    .database()
    .ref('notifications')
    .push(notifications);
  //console.log('RES ->', res);
}
async function notificateCompromisosCalendar(idResponsable, mensaje) {
  const notifications = {
    title: `Recordatorio`,
    idresponsable: idResponsable,
    visited: false,
    iconbg: '#ffffff',
    //icon: 'mdi-file-document',
    ticon: false,
    icon: require('@/assets/images/icon/calendario.svg'),
    desc: mensaje,
    to: '/actas/matriz-aac',
    time: moment().format('YYYY-MM-DD HH:mm:ss')
  };
  await fire
    .database()
    .ref('notifications')
    .push(notifications);
  //console.log('RES ->', res);
}
async function notificateOKR(idResponsable, mensaje) {
  const notifications = {
    title: `Recordatorio`,
    idresponsable: idResponsable,
    visited: false,
    iconbg: '#ffffff',
    //icon: 'mdi-file-document',
    ticon: false,
    icon: require('@/assets/images/icon/okr.svg'),
    desc: mensaje,
    to: '/okr/metodologia-okr',
    time: moment().format('YYYY-MM-DD HH:mm:ss')
  };
  await fire
    .database()
    .ref('notifications')
    .push(notifications);
  //console.log('RES ->', res);
}
async function notificateIndicador(idResponsable, mensaje) {
  const notifications = {
    title: `Recordatorio`,
    idresponsable: idResponsable,
    visited: false,
    iconbg: '#ffffff',
    //icon: 'mdi-file-document',
    ticon: false,
    icon: require('@/assets/images/icon/okr.svg'),
    desc: mensaje,
    to: '/okr/metodologia-okr',
    time: moment().format('YYYY-MM-DD HH:mm:ss')
  };
  await fire
    .database()
    .ref('notifications')
    .push(notifications);
  //console.log('RES ->', res);
}
async function notificateEventoCalendar(idResponsable, mensaje) {
  /* let mensaje = '';
  if (dias == 0) {
    mensaje = `Te queda menos de un día para la reunión comite personal ${evento} de ${hora}`;
  }
  if (dias < 0) {
    mensaje = `Ha pasado ${Math.abs(dias)} días desde que paso la reunión comite personal ${evento} de ${hora}`;
  }
  if (dias > 0) {
    mensaje = `Te quedan ${dias} días en el para la reunión comite personal ${evento} de ${hora}`;
  } */
  const notifications = {
    title: `Recordatorio`,
    idresponsable: idResponsable,
    visited: false,
    iconbg: '#ffffff',
    //icon: 'mdi-file-document',
    ticon: false,
    icon: require('@/assets/images/icon/calendario.svg'),
    desc: mensaje,
    to: '/calendario-comites/evento',
    time: moment().format('YYYY-MM-DD HH:mm:ss')
  };
  await fire
    .database()
    .ref('notifications')
    .push(notifications);
  //console.log('RES ->', res);
}
async function notificateComiteCalendar(idResponsable, mensaje) {
  const notifications = {
    title: `Recordatorio`,
    idresponsable: idResponsable,
    visited: false,
    iconbg: '#ffffff',
    //icon: 'mdi-file-document',
    ticon: false,
    icon: require('@/assets/images/icon/calendario.svg'),
    desc: mensaje,
    to: '/calendario-comites/comite',
    time: moment().format('YYYY-MM-DD HH:mm:ss')
  };
  await fire
    .database()
    .ref('notifications')
    .push(notifications);
  //console.log('RES ->', res);
}
async function notificateShareFolderAndFile(idResponsable, emisor, type, nombre) {
  const notifications = {
    title: `Nuevo ${type} compartido`,
    idresponsable: idResponsable,
    visited: false,
    iconbg: '#ffffff',
    //icon: 'mdi-file-document',
    ticon: false,
    icon: require('@/assets/images/icon/documentacion.svg'),
    desc: `<b>${emisor}</b> ha compartido con usted el ${type}, <b>${nombre}</b>`,
    to: '/documentacion/dashboard',
    time: moment().format('YYYY-MM-DD HH:mm:ss')
  };
  await fire
    .database()
    .ref('notifications')
    .push(notifications);
  //console.log('RES ->', res);
}

export {
  notificateActas,
  notificateComite,
  notificateEvento,
  notificateIndicadores,
  notificatePresupuesto,
  notificateAvancePresupuesto,
  notificateProyectos,
  notificateProyectosCalendar,
  notificateCompromisosCalendar,
  notificateComiteCalendar,
  notificateEventoCalendar,
  notificateOKR,
  notificateIndicador,
  notificateShareFolderAndFile
};
