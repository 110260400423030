<template>
  <div>
    <v-card-text class="mt-4 mb-4">
      <v-row>
        <v-col cols="12" md="12" class="py-2">
          <v-text-field
            dense
            label="URL"
            v-model="url"
            prepend-icon="mdi-web"
            v-auth-acl="'gh-pres-repre-insert'"
            append-icon="mdi-cloud-upload"
            @click:append="guardaUrl()"
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12" class="py-2">
          <span v-if="fileopexf" class="mr-4">
            OPEXF
          </span>
          <v-chip
            v-if="fileopexf"
            color="primary"
            dark
            close
            @click:close="
              fileopexf = false;
              nameopexf = null;
            "
          >
            <span> {{ nameopexf }} </span>
          </v-chip>
          <v-file-input
            v-else
            dense
            :rules="rules"
            v-model="opexf"
            accept=".zip,.rar,.7zip,.pdf,.png,.jpg,.jpeg,.pptx,.docx,.xlsx"
            show-size
            label="OPEXF"
            clearable
            append-icon="mdi-cloud-upload"
            v-auth-acl="'gh-pres-repre-insert'"
            @click:append="guardaOpexF()"
            @change="changeInputFileCA($event)"
          ></v-file-input>
        </v-col>
        <v-col cols="12" md="12" class="py-2">
          <span v-if="fileopexa" class="mr-4">
            OPEXA
          </span>
          <v-chip
            v-if="fileopexa"
            color="primary"
            dark
            close
            @click:close="
              fileopexa = false;
              nameopexa = null;
            "
          >
            <span> {{ nameopexa }} </span>
          </v-chip>
          <v-file-input
            dense
            v-else
            :rules="rules"
            v-model="opexa"
            accept=".zip,.rar,.7zip,.pdf,.png,.jpg,.jpeg,.pptx,.docx,.xlsx"
            show-size
            label="OPEXA"
            clearable
            v-auth-acl="'gh-pres-repre-insert'"
            append-icon="mdi-cloud-upload"
            @click:append="guardaOpexA()"
            @change="changeInputFileCA($event)"
          ></v-file-input>
        </v-col>
        <v-col cols="12" md="12" class="py-2">
          <span v-if="filecapexa" class="mr-4">
            CAPEXA
          </span>
          <v-chip
            v-if="filecapexa"
            color="primary"
            dark
            close
            @click:close="
              filecapexa = false;
              namecapexa = null;
            "
          >
            <span> {{ namecapexa }} </span>
          </v-chip>
          <v-file-input
            v-else
            dense
            :rules="rules"
            v-model="capexa"
            v-auth-acl="'gh-pres-repre-insert'"
            accept=".zip,.rar,.7zip,.pdf,.png,.jpg,.jpeg,.pptx,.docx,.xlsx"
            show-size
            label="CAPEX"
            clearable
            append-icon="mdi-cloud-upload"
            @click:append="guardaCapexA()"
            @change="changeInputFileCA($event)"
          ></v-file-input>
        </v-col>
      </v-row>
    </v-card-text>
    <!--<v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="error" @click="cancelar()">Cerrar</v-btn>
    </v-card-actions>-->
    <div v-if="showLoading" class="progress-background">
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </div>
  </div>
</template>

<script>
import PresupuestoService from '../services/PresupuestoService';
import { decryptAES } from '@/components4x/utils/utils4x';
import Swal from 'sweetalert2';
import { notificatePresupuesto } from '@/services/notificationService';
export default {
  name: 'PresupuestoFormulario',
  data: () => ({
    jefes: [],
    url: '',
    capexa: null,
    opexf: null,
    opexa: null,
    fileopexf: false,
    fileopexa: false,
    filecapexa: false,
    nameopexf: '',
    nameopexa: '',
    namecapexa: '',
    rules: [
      (value) => !value || value.size < 25000000 || 'El archivo debe de tener menos de 25 MB!',
      (value) => {
        if (value === null || value === undefined) return true;
        const res = value.name.slice(value.name.lastIndexOf('.') + 1, value.name.length).toLowerCase();
        if (
          res != 'exe' &&
          res != 'sql' &&
          res != 'jar' &&
          res != 'air' &&
          res != 'src' &&
          res != 'vb' &&
          res != 'bat' &&
          res != 'dll' &&
          res != 'com' &&
          res != 'bin' &&
          res != 'apk' &&
          res != 'app' &&
          res != 'cgi' &&
          res != 'wsf' &&
          res != 'gadget' &&
          res != 'log' &&
          res != 'css' &&
          res != 'sqlite' &&
          res != 'psd' &&
          res != 'ai' &&
          res != 'bak' &&
          res != 'js' &&
          res != 'cmd'
        )
          return true;
        return 'No se permite ese tipo de archivos.';
      }
    ],
    errorFileOF: false,
    errorFileOA: false,
    errorFileCA: false,
    presupuestoService: null,
    showLoading: false
  }),
  methods: {
    async guardaOpexF() {
      if (this.errorFileOF) {
        await this.alertDialog('warning', 'Tamaño o tipo de archivo no permitido');
        return;
      }
      this.showLoading = true;
      const emp = decryptAES(localStorage.getItem('emp'));
      const formData = new FormData();
      formData.append('idempresa', emp);
      formData.append('file1', this.opexf);
      const res = await this.presupuestoService.post().execResource('opexf', formData);
      if (res.status) {
        await this.alertDialog('success', res.data);
        if (this.opexf != null) {
          await this.notificarEmails(
            'Presupuesto OPEX FUNDO',
            '<span>Buen día estimad@, el motivo del presente es dar conformidad al presupuesto presentado, adjuntando la base de datos del presupuesto OPEX FUNDO. </span><a href="https://40.88.149.7:4433/presupuesto/dashboard">Presupuesto GIPLAN</a>'
          );
        }
        await this.cargarData();
      } else {
        await this.alertDialog('error', res.data);
      }
    },
    async guardaOpexA() {
      if (this.errorFileOA) {
        await this.alertDialog('warning', 'Tamaño o tipo de archivo no permitido');
        return;
      }
      this.showLoading = true;
      const emp = decryptAES(localStorage.getItem('emp'));
      const formData = new FormData();
      formData.append('idempresa', emp);
      formData.append('file2', this.opexa);
      const res = await this.presupuestoService.post().execResource('opexa', formData);
      if (res.status) {
        await this.alertDialog('success', res.data);
        if (this.opexa != null) {
          await this.notificarEmails(
            'Presupuesto OPEX ADM.',
            '<span>Buen día estimad@, el motivo del presente es dar conformidad al presupuesto presentado, adjuntando la base de datos del presupuesto OPEX ADM. </span><a href="https://40.88.149.7:4433/presupuesto/dashboard">Presupuesto GIPLAN</a>'
          );
        }
        await this.cargarData();
      } else {
        await this.alertDialog('error', res.data);
      }
    },
    async guardaCapexA() {
      if (this.errorFileCA) {
        await this.alertDialog('warning', 'Tamaño o tipo de archivo no permitido');
        return;
      }
      this.showLoading = true;
      const emp = decryptAES(localStorage.getItem('emp'));
      const formData = new FormData();
      formData.append('idempresa', emp);
      formData.append('file3', this.capexa);
      const res = await this.presupuestoService.post().execResource('capexa', formData);
      if (res.status) {
        await this.alertDialog('success', res.data);
        if (this.capexa != null) {
          await this.notificarEmails(
            'Presupuesto CAPEX',
            '<span>Buen día estimad@, el motivo del presente es dar conformidad al presupuesto presentado, adjuntando la base de datos del presupuesto CAPEX. </span><a href="https://40.88.149.7:4433/presupuesto/dashboard">Presupuesto GIPLAN</a>'
          );
        }
        await this.cargarData();
      } else {
        await this.alertDialog('error', res.data);
      }
    },
    async guardaUrl() {
      if (this.url == '') {
        await this.alertDialog('warning', 'Ingrese Url');
        return;
      }
      this.showLoading = true;
      const emp = decryptAES(localStorage.getItem('emp'));
      const res = await this.presupuestoService.post().execResource('url', {
        idempresa: emp,
        url: this.url
      });
      if (res.status) {
        await this.alertDialog('success', res.data);
        await this.notificarEmails(
          'Dashboard de presupuesto',
          '<span> Buen día estimad@, el motivo del presente es compartir el Dashboard de presupuesto. </span><a href="https://40.88.149.7:4433/presupuesto/dashboard">Dashboard Presupuesto GIPLAN</a>'
        );
        await this.cargarData();
      } else {
        await this.alertDialog('error', res.data);
      }
    },
    async notificarEmails(asunto, mensaje) {
      const invitados = [];
      const participantes = [];
      let invitadoNoti = [];
      let participantesNoti = [];

      this.jefes.forEach((el) => {
        invitados.push(el.code);
        participantes.push(el.email);
      });
      participantesNoti = [
        {
          email: participantes,
          subject: asunto,
          content: mensaje
        }
      ];
      invitadoNoti = new Set(invitados);
      for (const participant of invitadoNoti) {
        await notificatePresupuesto(participant, mensaje, asunto);
      }
      const res = await this.presupuestoService.post().execResource('sendemail', {
        participantes: participantesNoti
      });
      if (res.status) {
        await this.alertDialog('success', res.data);
      } else {
        await this.alertDialog('error', res.data);
      }
    },
    changeInputFileOF($event) {
      const file = $event === null || $event === undefined ? null : $event;

      if (file === null) {
        return;
      }

      this.errorFileOF = false;

      if (file.size > 25000000) {
        this.errorFileOF = true;
      }

      const res = file.name.slice(file.name.lastIndexOf('.') + 1, file.name.length).toLowerCase();
      if (
        res == 'exe' ||
        res == 'sql' ||
        res == 'jar' ||
        res == 'air' ||
        res == 'src' ||
        res == 'vb' ||
        res == 'bat' ||
        res == 'dll' ||
        res == 'com' ||
        res == 'bin' ||
        res == 'apk' ||
        res == 'app' ||
        res == 'cgi' ||
        res == 'wsf' ||
        res == 'gadget' ||
        res == 'log' ||
        res == 'css' ||
        res == 'sqlite' ||
        res == 'psd' ||
        res == 'ai' ||
        res == 'bak' ||
        res == 'js' ||
        res == 'cmd'
      ) {
        this.errorFileOF = true;
      }
    },
    changeInputFileOA($event) {
      const file = $event === null || $event === undefined ? null : $event;

      if (file === null) {
        return;
      }

      this.errorFileOA = false;

      if (file.size > 25000000) {
        this.errorFileOA = true;
      }

      const res = file.name.slice(file.name.lastIndexOf('.') + 1, file.name.length).toLowerCase();
      if (
        res == 'exe' ||
        res == 'sql' ||
        res == 'jar' ||
        res == 'air' ||
        res == 'src' ||
        res == 'vb' ||
        res == 'bat' ||
        res == 'dll' ||
        res == 'com' ||
        res == 'bin' ||
        res == 'apk' ||
        res == 'app' ||
        res == 'cgi' ||
        res == 'wsf' ||
        res == 'gadget' ||
        res == 'log' ||
        res == 'css' ||
        res == 'sqlite' ||
        res == 'psd' ||
        res == 'ai' ||
        res == 'bak' ||
        res == 'js' ||
        res == 'cmd'
      ) {
        this.errorFileOA = true;
      }
    },
    changeInputFileCA($event) {
      const file = $event === null || $event === undefined ? null : $event;

      if (file === null) {
        return;
      }

      this.errorFileCA = false;

      if (file.size > 25000000) {
        this.errorFileCA = true;
      }

      const res = file.name.slice(file.name.lastIndexOf('.') + 1, file.name.length).toLowerCase();
      if (
        res == 'exe' ||
        res == 'sql' ||
        res == 'jar' ||
        res == 'air' ||
        res == 'src' ||
        res == 'vb' ||
        res == 'bat' ||
        res == 'dll' ||
        res == 'com' ||
        res == 'bin' ||
        res == 'apk' ||
        res == 'app' ||
        res == 'cgi' ||
        res == 'wsf' ||
        res == 'gadget' ||
        res == 'log' ||
        res == 'css' ||
        res == 'sqlite' ||
        res == 'psd' ||
        res == 'ai' ||
        res == 'bak' ||
        res == 'js' ||
        res == 'cmd'
      ) {
        this.errorFileCA = true;
      }
    },
    async alertDialog(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        confirmButtonText: `OK`
      });
    },
    limpiarData() {
      this.url = '';
      this.opexf = null;
      this.opexa = null;
      this.capexa = null;
      this.errorFileOF = false;
      this.errorFileOA = false;
      this.errorFileCA = false;
      this.fileopexf = false;
      this.fileopexa = false;
      this.filecapexa = false;
      this.nameopexf = '';
      this.nameopexa = '';
      this.namecapexa = '';
    },
    async cargarData() {
      this.showLoading = true;
      this.limpiarData();
      const emp = decryptAES(localStorage.getItem('emp'));
      const res = await this.presupuestoService.get().execResource('archivos', {
        idempresa: emp
      });
      if (res.length > 0) {
        this.url = res[0].url == null ? '' : res[0].url;
        if (res[0].nombrefile1 != null) {
          this.fileopexf = true;
          this.nameopexf = res[0].nombrefile1;
        }
        if (res[0].nombrefile2 != null) {
          this.fileopexa = true;
          this.nameopexa = res[0].nombrefile2;
        }
        if (res[0].nombrefile3 != null) {
          this.filecapexa = true;
          this.namecapexa = res[0].nombrefile3;
        }
      }
      this.showLoading = false;
    },
    async listJefes() {
      const res = await this.presupuestoService.get().execResource('listJefes');
      if (res.length > 0)
        res[0].usuariosPresupuesto.forEach((el) => {
          this.jefes.push({
            code: el.idresponsable,
            dni: el.idtrabajador,
            idsubarea: el.idsubarea,
            name: el.nombresall,
            email: el.email,
            object: {}
          });
        });
    }
  },
  async created() {
    this.presupuestoService = this.$httpService(new PresupuestoService(), this);
    this.listJefes();
    this.cargarData();
  }
};
</script>
